import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import URLSearchParams from 'url-search-params';
import ConclusaoSucesso from './ConclusaoSucesso';
import ConclusaoErro from './ConclusaoErro';
import UrlHelper from '../Helpers/UrlHelper';

const styles = theme => ({
  containerConteudo: {
    width: '100%',
    minHeight: 600,
    padding: '15px 15% 55px',
    boxSizing: 'border-box',
    margin: '0px auto',
    position: 'relative',
    background: 'linear-gradient(180deg, #EBEBEB 0%, rgba(255, 255, 255, 0) 100%);',
  },
  btnContinuarLendo: {
    width: '80%',
    maxWidth: 388,
    height: 46,
    border: 0,
    background: '#EE6B25',
    fontSize: 16,
    color: 'white',
    fontWeight: 'bold',
    borderRadius: 10,
    cursor: 'pointer',
  },
  boxImagesRodape: {
    width: '100%',
    maxWidth: 400,
    position: 'relative',
    margin: '0px auto',
    display: 'flex',
    flexFlow: 'row wrap',
    alignContent: 'space-around',
    justifyContent: 'space-around',
    marginTop: 20,
    marginBottom: 90,
  },
});

class Conclusao extends React.Component {

  constructor(props) {
    super(props);

    const urlParams = this.props.location.search
    const params = new URLSearchParams(urlParams);
    const state = params.get('state') ? '&state=' + params.get('state') : '';

    const URL_GET_BACK = process.env.REACT_APP_BACK_NEWS_URL + '?client_id=' + process.env.REACT_APP_BACK_NEWS_CLIENT_ID + '&redirect_uri=' + process.env.REACT_APP_BACK_NEWS_REDIRECT_URI + state

    this.state = {
      redirectTo: false,
      url_redirect: URL_GET_BACK,
      error_code: props.match.params.error_code
    }
  }

  handleRedirect() {
    const urlState = UrlHelper.getQueryString('url_state');
    const redirectUrl = UrlHelper.buildRedirectUrl(urlState);
    window.location.href = redirectUrl;
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.containerConteudo}>
          {this.state.error_code ? <ConclusaoErro error_code={this.state.error_code} /> : <ConclusaoSucesso />}
          <button className={classes.btnContinuarLendo} type="button" onClick={() => this.handleRedirect()}>{this.state.error_code ? 'Voltar' : 'Continuar'}</button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Conclusao);
