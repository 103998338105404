import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularLoader from './CircularLoader';
import UrlHelper from '../Helpers/UrlHelper'
import ApiService from '../Services/ApiService';

const styles = theme => ({
  containerLoader: {
    width: '100%',
    minHeight: 200,
    background: 'white',
    padding: '225px 0 55px',
    position: 'relative',
    textAlign: 'center',
  },
});

class LogoutCallback extends Component {

  async componentDidMount() {
    await ApiService.logout();
    const state = UrlHelper.getQueryString('state');
    const stateArray = UrlHelper.decodeStates(state);
    const assinePage = stateArray[0] || '/';
    const nscTotalPage = stateArray[1];

    const url = UrlHelper.buildUrl(assinePage, { url_state: nscTotalPage });
    this.props.history.push(url);
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.containerLoader}>
          <CircularLoader />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(LogoutCallback);
