import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import AppNscDigital from '../Components/AppNscDigital';
import AppClubeNsc from '../Components/AppClubeNsc';
import ConclusaoParabens from '../Components/ConclusaoParabens';
import { Helmet } from 'react-helmet';
import { ErrorMessages } from '../Helpers/Utils';

const styles = theme => ({
  divTextApp: {
    width: '55%',
    margin: '4% auto',
    fontSize: '2rem',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#FF6915',
    '@media (max-width: 550px)': {
      fontSize: '1.6rem',
      padding: '1% 0 2%',
      width: '85%',
    }
  }
});

class ConclusaoDigitalUnisul extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error_code: props.match.params.error_code
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Helmet>
          <title>
            Assinatura digital com Clube NSC concluída| Assine o NSC Total
          </title>
          <meta
            name="description"
            content={`
              Parabéns, assinante!
              Agora você já pode usufruir de acesso
              ilimitado ao maior portal de Santa Catarina.
            `} />
        </Helmet>

        <ConclusaoParabens
          errorCode={this.state.error_code}
          title={this.state.error_code ? ErrorMessages.getTitle(this.state.error_code) : 'Parabéns!'}
          message={this.state.error_code ? ErrorMessages.getMessage(this.state.error_code) :
            `Agora você tem 5 meses de NSC Digital + Clube NSC. Você tem acesso ilimitado ao melhor conteúdo de Santa Catarina além de descontos do Clube NSC.`} />

        <AppNscDigital />
        <AppClubeNsc />

        <div className={classes.divTextApp}>
          Pelo app ou pelo site, você tem em mãos o que há de melhor do jornalismo catarinense.
        </div>

      </div>
    );
  }
}

export default withStyles(styles)(ConclusaoDigitalUnisul);
