import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { NSCTotalService } from '../Services/NSCTotalService';
import logo from '../img/logo_topo.svg'
import logoBranco from '../img/logo_branco.svg'
import UrlHelper from '../Helpers/UrlHelper';
// import UserSession from '../Services/UserSession';
import AuthStepper from '../Components/Stepper/AuthStepper';

const styles = theme => ({
  topBar: {
    // minHeight: '99px',
    // height: '99px',
    borderBottom: '1px solid rgb(225, 224, 224)',
    [theme.breakpoints.down('sm')]: {
      minHeight: '70px',
      height: '75px',
      maxHeight: '80px'
    },
  },
  header: {
    display: 'block',
    width: '100%',
    height: '42px',
    minHeight: '42px',
    backgroundColor: 'white',
    boxSizing: 'unset',
    borderBottom: '1px solid rgb(225, 224, 224)',
    [theme.breakpoints.down('sm')]: {
      borderBottom: 'none',
      height: '40px',
    },
  },
  headerTopBox: {
    position: 'static',
    display: 'inline-block',
    top: '0px',
    width: '100%',
    zIndex: '99',
    minHeight: '42px',
    height: '42px',

  },
  headerBlack: {
    display: 'none',
    height: '45px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    color: 'white',
    width: '100%',
    '& a': {
      height: '32px',
      color: '-webkit-link',
      cursor: 'pointer',
      textDecoration: 'underline',
      '& img': {
        fill: 'white',
      }
    }
  },
  topBarContainer: {
    display: 'block',
    backgroundColor: 'rgb(255, 255, 255)',
    width: '100%',
    padding: '0px',
  },
  containerPrincipal: {
    margin: 'auto',
    '@media screen and (min-width: 996px)': {
      maxWidth: '80%',
    },
    '@media screen and (min-width: 768px)': {
      maxWidth: '90%',
      paddingRight: '1rem',
    },
    '@media screen and (min-width: 480px)': {
      maxWidth: '100%',
    },
    '@media screen and (min-width: 1200px)': {
      maxWidth: '1260px',
    },
  },

  listItems: {
    width: '100%',
    display: 'inline-block',
    minHeight: '42px',
    height: '42px',
    listStyleType: 'none',
    margin: '0px 0px 0px -20px',
    padding: '0px',
    overflow: 'hidden',
    marginBlockStart: '0',
    marginBlockEnd: '0',
    marginInlineStart: '0px',
    marginInlineEnd: '0px',
    paddingInlineStart: '0px',
    float: 'left',
    fontSize: '17px',
    fontWeight: 'bolder',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '& li': {
      display: 'list-item',
      textAlign: '-webkit-match-parent',
      listStyleType: 'none',
      float: 'left',
      fontSize: '17px',
      fontWeight: 'bolder',
      position: 'relative',
      '& :first-child': {
        '& a': {
          display: 'block',
          color: 'rgb(255, 105, 21)',
          textAlign: 'center',
          padding: '10px 20px',
          textDecoration: 'none',
        }
      },
      '& a': {
        display: 'block',
        color: 'rgb(113, 113, 113)',
        textAlign: 'center',
        padding: '10px 20px',
        textDecoration: 'none',
      },
      '& a:hover': {
        backgroundColor: 'rgb(221, 221, 221)',
      },
    },
    '& li:first-child': {
      '& a': {
        display: 'block',
        color: 'rgb(255, 105, 21)',
        textAlign: 'center',
        padding: '10px 20px',
        textDecoration: 'none',
      },
    },
    '& li:not(:last-child)': {
      marginRight: '-2px',
    },

    '& li:not(:first-child)::before': {
      content: '""',
      top: '50%',
      transform: 'translateY(-50%)',
      position: 'absolute',
      width: '2px',
      height: '14px',
      backgroundColor: 'rgb(222, 222, 222)',
      left: '0px',
    },
  },

  headerContainer: {
    width: '100%',
    maxWidth: '1260px',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxSizing: 'border-box',
    height: '100%',
    minHeight: '100%',
    top: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    '& button:hover': {
      opacity: '0.6',
    },
  },
  sandwichMenuButton: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    display: 'inline-block',
    outline: 'none',
    marginRight: '2rem',
    boxSizing: 'border-box',
    verticalAlign: 'middle',
    cursor: 'pointer',
    padding: '0px',
    borderWidth: '0px',
    borderStyle: 'initial',
    borderColor: 'initial',
    borderImage: 'initial',
    background: 'none',
    // marginTop: '15px',
    textRendering: 'auto',
    color: 'buttontext',
    letterSpacing: 'normal',
    wordSpacing: 'normal',
    textTransform: 'none',
    textIndent: '0px',
    textShadow: 'none',
    textAlign: 'center',
    alignItems: 'flex-start',
    margin: '0em',
    font: '400 13.3333px Arial',
    '& i + i': {
      marginTop: '5px',
    },
    '& i': {
      background: 'rgb(255, 105, 21)',
      display: 'block',
      width: '20px',
      height: '2px',
      fontStyle: 'italic',
      cursor: 'pointer',
    }
  },
  sandwichMenuMobileButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    display: 'inline-block',
    outline: 'none',
    marginRight: '2rem',
    boxSizing: 'border-box',
    verticalAlign: 'middle',
    cursor: 'pointer',
    padding: '0px',
    borderWidth: '0px',
    borderStyle: 'initial',
    borderColor: 'initial',
    borderImage: 'initial',
    background: 'none',
    marginTop: '15px',
    textRendering: 'auto',
    color: 'buttontext',
    letterSpacing: 'normal',
    wordSpacing: 'normal',
    textTransform: 'none',
    textIndent: '0px',
    textShadow: 'none',
    textAlign: 'center',
    alignItems: 'flex-start',
    margin: '0em',
    font: '400 13.3333px Arial',
    '& i + i': {
      marginTop: '5px',
    },
    '& i': {
      background: 'rgb(255, 105, 21)',
      display: 'block',
      width: '20px',
      height: '2px',
      fontStyle: 'italic',
      cursor: 'pointer',
    }
  },
  logoWrapper: {
    margin: '0px auto',
    display: 'block',
    '& a': {
      textAlign: 'left',
      color: '-webkit-link',
      cursor: 'pointer',
      textDecoration: 'underline',
      '& img': {
        fill: 'rgb(255, 105, 21)',
        width: '85px',
        height: '34px',
        marginTop: '4px',
        // marginTop: '15px',
      }
    },
  },

  containerRight: {
    float: 'right',
    display: 'inline-flex',
    alignItems: 'center',
    margin: '5px 0 0 0',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    '& .buttonAssineJa': {
      boxSizing: 'border-box',
      outlineStyle: 'none',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '0.85em',
      backgroundColor: 'rgb(255, 105, 21)',
      color: 'white',
      display: 'none', // hide assine já button
      cursor: 'pointer',
      height: 'fit-content',
      borderWidth: 'initial',
      borderStyle: 'none',
      borderColor: 'initial',
      borderImage: 'initial',
      borderRadius: '0.2em',
      margin: '0px 0.5em 0px 0px',
      padding: '0.5em 1em',
    },
    '& .buttonLogin': {
      boxSizing: 'border-box',
      outlineStyle: 'none',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '0.85em',
      backgroundColor: 'white',
      display: 'block',
      cursor: 'pointer',
      height: 'fit-content',
      color: 'rgb(113, 113, 113)',
      borderRadius: '0.2em',
      margin: '0px 0px 0px 0.5em',
      padding: '0.5em 0.75em',
      borderWidth: 'initial',
      borderStyle: 'none',
      borderColor: 'initial',
      borderImage: 'initial',
    },
    '& svg': {
      fontSize: '1.4em',
      verticalAlign: 'middle',
    }
  },

  weatherWrapper: {
    position: 'relative',
    float: 'right',
    marginRight: '4px',
    minWidth: '90px',
    textAlign: 'right',
    display: 'block',
    height: '33px',
    '& a': {
      display: 'inline-block',
      height: '20px',
      alignItems: 'center',
      textDecoration: 'none',
      color: '-webkit-link',
      cursor: 'pointer',
      '& svg': {
        marginBottom: '-6px',
        '@media screen and (min-width: 340px)': {
          width: '20px',
          height: '20px',
          textAlign: 'right',
        }
      },
      '& span': {
        marginLeft: '0.5rem',
        fontSize: '1.5rem',
        color: 'rgb(255, 105, 21)',
        display: 'inline-block',
        textAlign: 'right',
        fontWeight: 'bold',
      },
    },
    '& span': {
      display: 'block',
      '& a': {
        color: 'rgb(255, 105, 21)',
        fontSize: '0.75rem',
        fontWeight: 'bold',
        textDecoration: 'underline',
      }
    },
  },

  mainMenuVoltarButton: {
    outline: 'none',
    cursor: 'pointer',
    fontFamily: '"Source Sans Pro", sans',
    fontSize: '18px',
    color: 'rgb(255, 105, 21)',
    fontWeight: 'bold',
    borderWidth: '0px',
    borderStyle: 'initial',
    borderColor: 'initial',
    borderImage: 'initial',
    background: 'none',
    margin: '0px',
    padding: '20px',
    '& svg': {
      position: 'relative',
      left: '-10px',
      transform: 'rotate(180deg)',
      fill: 'rgb(255, 105, 21)',
      height: '16px',
      maxWidth: '16px',
    }
  },
  mainMenu: {
    position: 'absolute',
    top: '50px',
    left: '-20px',
    zIndex: '99',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    '& .styledContainer': {
      backgroundColor: 'white',
      width: '400px',
      position: 'relative',
      display: 'block',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'rgb(225, 224, 224)',
      borderImage: 'initial',
      borderRadius: '5px',
      [theme.breakpoints.down('md')]: {
        height: '100%',
        position: 'fixed',
        width: '0px',
        top: '0px',
        left: '-300px',
        zIndex: '100',
        boxShadow: 'rgba(0, 0, 0, 0.24) 4px 6px 4px',
        background: 'white',
        transition: 'all 400ms ease-in 0s',
        borderRadius: '0px',
        borderStyle: 'none',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        backgroundColor: 'white',
        width: '400px',
        position: 'absolute',
        display: 'block',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'rgb(225, 224, 224)',
        borderImage: 'initial',
        borderRadius: '5px',
        left: '0px',
      },
      '& .overflowContainer': {
        display: 'block',
        overflowY: 'auto',
        background: 'white',
        borderRadius: '5px',
        [theme.breakpoints.down('sm')]: {
          height: '100%',
          borderRadius: '0px',
          border: 'none',
        },
        '@media screen and (min-width: 1200px)': {
          maxHeight: '400px',
        },
        '& header': {
          borderBottom: '2px solid rgb(235, 235, 235)',
          [theme.breakpoints.down('xs')]: {
            borderBottom: 'none',
          },
        },
        '& .menuItems': {
          '@media screen and (min-width: 1200px)': {
            padding: '5px 0px',
          },
          display: 'block',
          position: 'relative',
          margin: '0px',
          overflow: 'hidden',
          '& ul': {
            position: 'relative',
            backgroundColor: 'white',
            textAlign: 'left',
            margin: '0px',
            padding: '0px',
            display: 'block',
            listStyleType: 'disc',
            marginBlockEnd: '1em',
            marginInlineStart: '0px',
            marginInlineEnd: '0px',
            '& li:hover': {
              cursor: 'pointer',
              color: 'rgb(255, 105, 21)',
              backgroundColor: 'rgb(245, 245, 245)',
              '& svg': {
                fill: 'rgb(255, 105, 21)',
              }
            },
            '& li': {
              listStyleType: 'none',
              fontFamily: '"Source Sans Pro", sans',
              fontSize: '18px',
              fontWeight: 'bold',
              lineHeight: '2.5em',
              display: 'flex',
              alignItems: 'center',
              color: 'rgb(36, 37, 38)',
              justifyContent: 'space-between',
              padding: '0px 20px',
              '& svg': {
                fill: 'rgb(227, 221, 221)',
                height: '20px',
                maxWidth: '20px',
                marginRight: '-8px',
              },
            },
            '& a': {
              textDecoration: 'none',
              textAlign: 'left',
              '& li': {
                listStyleType: 'none',
                fontFamily: '"Source Sans Pro", sans',
                fontSize: '18px',
                fontWeight: 'bold',
                lineHeight: '2.5em',
                display: 'flex',
                alignItems: 'center',
                color: 'rgb(36, 37, 38)',
                justifyContent: 'space-between',
                padding: '0px 20px',
              },
              '& li:hover': {
                color: 'rgb(255, 105, 21)',
                backgroundColor: 'rgb(245, 245, 245)',
              },
            },
          },
        }
      },
      '& .overflowContainer::before': {
        [theme.breakpoints.up('md')]: {
          content: '""',
          width: '0px',
          height: '0px',
          position: 'absolute',
          top: '-17px',
          left: '30px',
          borderLeft: '15px solid transparent',
          borderRight: '15px solid transparent',
          borderBottom: '15px solid rgb(225, 224, 224)',
        },
      },
      '& .overflowContainer::after': {
        [theme.breakpoints.up('md')]: {
          content: '""',
          width: '0px',
          height: '0px',
          position: 'absolute',
          top: '-15px',
          left: '30px',
          borderLeft: '15px solid transparent',
          borderRight: '15px solid transparent',
          borderBottom: '15px solid white',
        },
      },

    },
  },
  menuFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    borderTop: '2px solid rgb(235, 235, 235)',
    padding: '20px',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '30px',
    },
    '& .socialInfoContainer': {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '1em',
      '& .socialIconsContainer': {
        margin: '0px',
        display: 'inline-block',
        textAlign: 'center',
        '& .socialIcons': {
          '& :hover': {
            fill: 'rgb(86, 185, 121)',
          },
          display: 'inline-block',
          height: '32px',
          width: '32px',
          lineHeight: '1.75em',
          textAlign: 'center',
          margin: '0px auto',
          borderRadius: '50%',
          '& svg': {
            width: 'auto',
            fill: 'rgb(36, 36, 37)',
            height: '18px',
            verticalAlign: 'middle',
          },
        }
      },
      '& .styledContactInfo': {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        '& a': {
          color: 'inherit',
          fontSize: '11px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          paddingBottom: '0.5em',
          textDecoration: 'none',
        },
        '& a:hover': {
          color: 'rgb(255, 105, 21)',
        }
      },
    },
    '& .styledLink': {
      textDecoration: 'none',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
      '& button': {
        boxSizing: 'border-box',
        outlineStyle: 'none',
        cursor: 'pointer',
        textAlign: 'center',
        color: 'white',
        fontWeight: 'bold',
        backgroundColor: 'rgb(255, 105, 21)',
        padding: '1em 1.8em',
        borderWidth: 'initial',
        borderStyle: 'none',
        borderColor: 'initial',
        borderImage: 'initial',
        borderRadius: '0.2em',
        '@media screen and (min-width: 1200px)': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
          width: '80%',
          fontSize: '17px',
          margin: '0px auto',
        },
        '& img': {
          display: 'inline-block',
          width: '32px',
          paddingLeft: '10px',
          marginBottom: '-5px',
          cursor: 'pointer',
          textAlign: 'center',
          color: 'white',
          fontWeight: 'bold',
        }
      }
    }
  },
  mobileMenuHeader: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    display: 'block',
    padding: '20px',
    '& .userDataContainer': {
      '& .buttonSair': {
        display: 'inline-block',
        boxSizing: 'border-box',
        outlineStyle: 'none',
        cursor: 'pointer',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '0.85em',
        backgroundColor: 'rgb(255, 105, 21)',
        color: 'white',
        margin: '1em 1em 1em 0px',
        padding: '1em 1.8em',
        borderWidth: 'initial',
        borderStyle: 'none',
        borderColor: 'initial',
        borderImage: 'initial',
        borderRadius: '0.2em',
      },
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'center',
      '& button': {
        boxSizing: 'border-box',
        outlineStyle: 'none',
        cursor: 'pointer',
        textAlign: 'center',
        backgroundColor: 'white',
        minWidth: '67px',
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Roboto, "Source Sans Pro", sans',
        fontSize: '17px',
        color: 'rgb(255, 105, 21)',
        textTransform: 'lowercase',
        borderWidth: 'initial',
        borderStyle: 'none',
        borderColor: 'initial',
        borderImage: 'initial',
        borderRadius: '0.2em',
        margin: '0px',
        padding: '0.5em 0px',
        '& .styledPersonIcon': {
          width: '40px',
          height: '40px',
          backgroundColor: 'rgb(232, 232, 232)',
          fill: 'rgb(255, 105, 21)',
          display: 'inline-block',
          position: 'relative',
          marginRight: '0.5rem',
          borderRadius: '50%',
          overflow: 'hidden',
          cursor: 'pointer',
          textAlign: 'center',
          fontFamily: 'Roboto, "Source Sans Pro", sans',
          fontSize: '17px',
          color: 'rgb(255, 105, 21)',
          textTransform: 'lowercase',
        }
      }
    },
  },
  mobileVeicles: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    display: 'block',
    backgroundColor: 'rgb(245, 245, 245)',
    padding: '10px',
    '& .styledJournalIconsContainer': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      height: '59px',
      justifyContent: 'end',
      paddingBottom: '1em',
      transition: 'all 250ms ease 0s',
      overflow: 'hidden',
      '& a': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'calc(25% - 5px)',
        height: '56px',
        marginTop: '10px',
        marginRight: '0.25em',
        boxSizing: 'border-box',
        background: 'linear-gradient(rgb(0, 109, 241) 0%, rgb(0, 200, 254) 100%)',
        borderRadius: '5px',
        padding: '0.25em',
        '& img': {
          width: '49px',
          maxHeight: '40px',
        },
      },
    },
    '& .styledTextButton': {
      backgroundColor: 'inherit',
      width: '100%',
      fontSize: '14px',
      fontWeight: 'bold',
      color: 'rgb(178, 178, 178)',
      borderWidth: 'initial',
      borderStyle: 'none',
      borderColor: 'initial',
      borderImage: 'initial',
      padding: '1em',
      margin: '0px auto',
      overflow: 'hidden',
      outline: 'none',
      '& span': {
        overflow: 'hidden',
      },
      '& svg': {
        fill: 'rgb(178, 178, 178)',
        height: '12px',
        maxWidth: '12px',
        marginLeft: '3px',
        marginBottom: '-7px',
        padding: '5px',
        overflow: 'hidden',
        fontSize: '14px',
        fontWeight: 'bold',
      },
    },
  },
  menuMobileBackground: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    display: 'block',
    position: 'fixed',
    top: '0px',
    left: '0px',
    height: '100%',
    width: '100%',
    zIndex: '0',
    opacity: '0.8',
    background: 'black',
  },
  stepperContainer: {
    width: '45%',
    margin: '0 auto 0 auto',
    '@media (max-width: 450px)': {
      width: '55%',
      margin: '0 auto 0 28%'
    }
  },
  logoStepperWrapper: {
    position: 'absolute',
    top: '20%',
    display: 'flex',
    marginRight: 'auto',
    marginLeft: '2%',
    width: '20%',
    '& img': {
      width: '80%'
    },
    '@media (max-width: 420px)': {
      '& img': {
        width: '100%'
      }
    }
  }
});

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.overFlowMenuRef = React.createRef();
    this.state = {
      showMenu: {},
      showMobileMenu: {},
      showUserMenu: false,
      isSubmenu: false,
      sideBarLinks: [],
      topBarMenu: [],
      temperature: '0',
      weatherDescription: '',
      weatherIcon: 7,
      mobileContainerMostrarMais: '59px',
      urlState: UrlHelper.getQueryString('url_state'),
      userData: {},
      userLoaded: false
    };
  }

  async componentDidMount() {
    // UserSession.getUser().then(userData => this.setState({ userData, userLoaded: true }));

    const results = await Promise.all([
      NSCTotalService.getMenus(),
      //NSCTotalService.getWeather()
    ]);

    const allMenus = results[0]
    // const weather = results[1];

    this.setState({
      sideBarLinks: allMenus.sidebar.links,
      topBarMenu: allMenus.topbar.links,
      //temperature: weather.weather.temperature.toFixed(),
      // weatherDescription: weather.weather.label,
      // weatherIcon: weather.weather.icon
    });
  }

  handleMenuChange = () => {
    this.setState({ showMobileMenu: {} });
    const menuVisibility = (this.state.showMenu.display === 'block') ? { display: 'none' } : { display: 'block' };
    this.setState({ showMenu: menuVisibility });
  }

  handleMenuMobileChange = () => {
    this.setState({ showMenu: {} });
    const menuStyle = (this.state.showMobileMenu.width === '268px') ? { width: '0px', left: '-300px' } : { width: '268px', left: '0px' };
    this.setState({ showMobileMenu: menuStyle });
  }

  handleUserMenuChange = () => {
    this.popup.handleUserMenuChange();
  }

  handleBackButton = () => {
    NSCTotalService.getMenus().then((menus) => {
      this.setState({
        isSubmenu: false,
        sideBarLinks: menus.sidebar.links,
      })
    });
  }

  handleSubmenuClick = (items) => {
    this.setState({
      isSubmenu: true,
      sideBarLinks: items,
    });
    this.overFlowMenuRef.current.scrollTo({ top: '0', behavior: 'smooth' });
  }

  handleMobileMenuMostrarMais = () => {
    this.setState({ mobileContainerMostrarMais: (this.state.mobileContainerMostrarMais === '200px' ? '59px' : '200px') });
  }

  render() {
    const { classes } = this.props;
    const showMobileOverflow = (this.state.showMobileMenu.width === '268px') ? { display: 'block' } : { display: 'none' };

    return (
      <div className={classes.topBar}>
        {this.props.step &&
          <div>
            <div className={classes.logoStepperWrapper}>
              <a href="https://www.nsctotal.com.br/home">
                <img src={logo} alt="NSC Total" />
              </a>
            </div>
            <div className={classes.stepperContainer}>
              <AuthStepper activeStep={this.props.step} renderOnlyThisStep={this.props.renderOnlyThisStep} />
            </div>
          </div>}
        {!this.props.step && <div className={classes.headerTopBox}>
          <header className={classes.headerBlack}>
            <a href="/">
              <img src={logo} alt="" />
            </a>
          </header>
          <div className={classes.topBarContainer}>
            <div className={classes.containerRight}>
              <button className="buttonAssineJa">Assine já</button>
            </div>
          </div>
          <header className={classes.header}>
            <div className={classes.headerContainer}>
              {/*<button className={classes.sandwichMenuButton} onClick={this.handleMenuChange}>
                <i></i>
                <i></i>
                <i></i>
              </button> */}
              <button className={classes.sandwichMenuMobileButton} onClick={this.handleMenuMobileChange}>
                <i></i>
                <i></i>
                <i></i>
              </button>
              <div className={classes.logoWrapper}>
                <a href="https://www.nsctotal.com.br/home">
                  <img src={logo} alt="NSC Total" />
                </a>
              </div>

              {/* MAIN MENU */}
              <div className={classes.mainMenu} style={this.state.showMenu}>
                <div className="styledContainer" style={this.state.showMobileMenu}>
                  <div ref={this.overFlowMenuRef} className="overflowContainer">

                    <div className="menuItems">
                      <ul>
                        {(this.state.isSubmenu) &&
                          <button className={classes.mainMenuVoltarButton} onClick={this.handleBackButton}>
                            <svg viewBox="0 0 10 10" className="caret">
                              <path fillRule="evenodd" d="M1.098 0l4.38 4.386-4.38 4.38L0 7.674l3.288-3.288L0 1.092z"></path>
                            </svg>voltar
                          </button>
                        }
                        {this.state.sideBarLinks.map((menuItem, index) => {
                          if (!menuItem.url.path) {
                            return (
                              <li onClick={this.handleSubmenuClick.bind(this, menuItem.links)} key={`menu-${index}`}>
                                {menuItem.label}
                                <svg viewBox="0 0 10 10" className="caret">
                                  <path fillRule="evenodd" d="M1.098 0l4.38 4.386-4.38 4.38L0 7.674l3.288-3.288L0 1.092z"></path>
                                </svg>
                              </li>
                            );
                          } else {
                            return (
                              <a href={menuItem.url.path} key={`menu-${index}`}>
                                <li>{menuItem.label}</li>
                              </a>
                            );
                          }
                        })}

                      </ul>
                    </div>

                    <footer className={classes.menuFooter}>
                      <div className="socialInfoContainer">
                        <div className="socialIconsContainer">
                          <a className="socialIcons" href="https://www.facebook.com/nsctotal" target="_blank" without="without" rel="noopener noreferrer" >
                            <svg viewBox="0 0 6 14">
                              <path fillRule="evenodd" d="M1.251 13.748h2.501v-6.87H5.63L6 4.73H3.752V3.175c0-.501.325-1.028.788-1.028h1.277V0H4.252v.01C1.802.099 1.3 1.517 1.256 3.007H1.25v1.724H0v2.146h1.251v6.87z"></path>
                            </svg>
                          </a>
                          <a className="socialIcons" href="https://www.instagram.com/nsctotal/" target="_blank" without="without" rel="noopener noreferrer" >
                            <svg viewBox="0 0 16 15">
                              <path fillRule="evenodd" d="M13.927 13.125c0 .26-.21.469-.469.469H2.208a.469.469 0 0 1-.469-.469V6.094h1.875c-.244.351-.326 1.007-.326 1.423a4.563 4.563 0 0 0 9.126 0c0-.416-.06-1.062-.362-1.423h1.875v7.031zM7.85 4.64a2.882 2.882 0 0 1 2.885 2.877 2.882 2.882 0 0 1-2.885 2.879 2.882 2.882 0 0 1-2.884-2.879A2.882 2.882 0 0 1 7.85 4.64zm3.264-2.296a.47.47 0 0 1 .47-.47h1.405a.47.47 0 0 1 .47.47V3.75a.47.47 0 0 1-.47.469h-1.406a.469.469 0 0 1-.469-.469V2.344zM13.55 0H2.116A1.78 1.78 0 0 0 .333 1.779V13.22c0 .983.798 1.78 1.783 1.78H13.55c.985 0 1.783-.797 1.783-1.78V1.78C15.333.796 14.535 0 13.55 0z"></path>
                            </svg>
                          </a>
                        </div>
                        <div className="styledContactInfo">
                          <a href="https://www.nsccomunicacao.com.br/trabalhe-conosco/#trabalhe-conosco">Trabalhe Conosco</a>
                          <a href="https://www.nsccomunicacao.com.br/contato/#contato">Fale Conosco</a>
                        </div>
                      </div>
                      <a className="styledLink" href="https://negociossc.com.br">
                        <button className="Button">anuncie na <img src={logoBranco} alt="NSC logo" /></button>
                      </a>
                    </footer>

                  </div>
                </div>
                <div className={classes.menuMobileBackground} style={showMobileOverflow} onClick={this.handleMenuMobileChange}></div>
              </div>
              {/* END MAIN MENU */}
            </div>
          </header>
        </div>}
      </div>
    );
  }
}

export default withStyles(styles)(Header);
