
import { getEnv } from'../Helpers/Utils.js';

const CLIENT_ID  = getEnv('REACT_APP_ASSINE_NSC_CLIENT_ID');
const REDIRECT_URI  = getEnv('REACT_APP_ASSINE_NSC_REDIRECT_URI');
const URL_NSC_AUTH = getEnv('REACT_APP_NSC_URL_NSC_AUTH');

class NSCAuthService {

  static async createCode() {
    const body = {
      clientId: CLIENT_ID,
      redirectUri: REDIRECT_URI
    }
    const URL = URL_NSC_AUTH + 'sso/code';
    const ret = await fetch(URL, {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(body),
    })
    .then(async response => {
      return response.json();
    })
    .then(responseJson => {
      return responseJson
    });
    return ret
  }
}

export default NSCAuthService;
