import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  policyContainer: {
    margin: 0,
    padding: 0,
    textAlign: 'left',
    fontFamily: 'Source Sans Pro',
    background: 'linear-gradient(180deg, #EBEBEB 0%, rgba(255, 255, 255, 0) 100%);'
  },

  content: {
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      margin: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      width: '95%',
      margin: 'auto',
    },
    paddingTop: 30,
    paddingBottom: 60,
  },

  mainTitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#FF6915',
    marginBottom: 0,
    marginTop: '35px',
    lineHeight: 1.4,
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '36px',
    },
  },

  bulletTitle: {
    color: '#4D4D4D',
    fontStyle: 'normal',
    fontWeight: 'bold',
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
  },

  textParagraph: {
    color: '#4D4D4D',
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    textAlign: 'justify',
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '12px',
    },
  },
});

class PoliticasDeUso extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.policyContainer}>
          <div className={classes.content}>
            <h3 className={classes.mainTitle}>Política de Privacidade de Dados</h3>
            <p className={classes.textParagraph}>Esta Política de Privacidade tem por objetivo informar os direitos dos titulares dos dados pessoais e descrever a forma como tratamos os dados pessoais das pessoas físicas que se relacionam com a NSC Comunicação.</p>
            <p className={classes.textParagraph}>A NSC Comunicação reforça seu compromisso de cumprimento da legislação vigente, informa que esta política pode ser alterada de tempos em tempos, razão pela qual recomenda sua leitura periodicamente.</p>

            <h5 className={classes.bulletTitle}>1. O que são dados pessoais?</h5>
            <p className={classes.textParagraph}>Dados pessoais são as informações relativas a uma pessoa física, identificada ou identificável, direta ou indiretamente, por exemplo, pelo nome, documentos de identificação, IP (número identificação), cookies, dados de localização. Os titulares dos dados pessoais são, neste caso, os usuários, ou Utilizadores dos serviços da NSC Comunicação, ou seja, seus assinantes, leitores, clientes, etc.</p>

            <h5 className={classes.bulletTitle}>2. Que dados pessoais são recolhidos e tratados? </h5>
            <p className={classes.textParagraph}>A NSC Comunicação poderá recolher e tratar diferentes categorias de dados pessoais dos Utilizadores como os dados de identificação, contato, serviço, interesses e perfis de utilização. Coletamos os dados que você nos fornece diretamente e aqueles obtidos indiretamente, quando você navega, conforme explicamos em nossa Política de Cookies.</p>
            <p className={classes.textParagraph}>Você deve, no momento do acesso, do preenchimento ou do envio de dados,  informá-los de forma precisa e verdadeira. Você não pode escolher expressões que possam trazer repercussão jurídica negativa para você ou para a NSC Comunicação, como exemplo, marcas comerciais, nomes comerciais, nomes ou pseudônimos de caracteres de relevância pública ou famosa e/ou quaisquer dados que não lhe pertencem ou cujo uso você não está autorizado.</p>

            <h5 className={classes.bulletTitle}>3. Quando a NSC recolhe os seus dados pessoais?</h5>
            <p className={classes.textParagraph}>Os dados pessoais dos Utilizadores serão recolhidos quando estes subscrevem, aderem ou utilizam um serviço da NSC, por exemplo, quando assinam um serviço, ou uma publicação, ou ainda newsletters, quando se registam através da criação de uma conta de cliente, quando instalam uma aplicação/app, quando participam de promoções comerciais, concursos culturais, ou afins.</p>

            <h5 className={classes.bulletTitle}>4. Por que os dados pessoais são recolhidos e tratados? </h5>
            <p className={classes.textParagraph}>A NSC Comunicação trata os dados pessoais dos Utilizadores para diferentes finalidades:</p>
            <ul>
              <p className={classes.textParagraph}>a) Execução de diligências pré-contratuais, como nos casos em que o tratamento dos dados pessoais seja necessário para a adesão ou subscrição de um serviço da NSC Comunicação, ou para possibilitar o envio de newsletters da NSC;</p>
              <p className={classes.textParagraph}>b) Prestação dos serviços, ou execução de um contrato, incluindo adesão aos serviços (assinaturas e subscrições), gestão de conteúdos, contatos para informações, gestão administrativa, contábil e fiscal, inclusive faturamentos, pedidos e gravação de chamadas para prova de transações comerciais, ou quaisquer outras comunicações que dizem respeito à relação contratual;</p>
              <p className={classes.textParagraph}>c) Recolhimento de dados para determinar o número de usuários, ou para compreender o modo como os usuários utilizam as plataformas digitais e para identificar o percurso que é feito dentro dos mesmos, com objetivo de melhoria contínua, e para que seja possível proporcionar uma experiência otimizada;</p>
              <p className={classes.textParagraph}>d) Inserção de publicidade nas plataformas digitais da NSC e análise de perfis de navegação. O desenho de perfis de navegação nas plataformas digitais da NSC tem a função de disponibilizar conteúdos e gerar sugestões personalizadas, de forma a melhor atender o Utilizador;</p>
              <p className={classes.textParagraph}>e) Análise da utilização das plataformas digitais e serviços NSC, definição de público alvo na preparação de eventos, ativação de marca, estudos de mercado, também com o objetivo de disponibilizar conteúdos e gerar sugestões personalizadas, de forma a melhor atender o Utilizador;</p>
              <p className={classes.textParagraph}>f) Por legítimo interesse da NSC Comunicação ou de terceiro, objetivando o apoio e promoção de atividades do controlador, por exemplo, a melhoria e o desenvolvimento de serviços;</p>
              <p className={classes.textParagraph}>g) Marketing, incluindo venda ou marketing de serviços, análise de perfis e preferências de utilização dos serviços, melhoria e desenvolvimento dos serviços e da experiência de utilização, oferta de conteúdos, gestão de conteúdos disponibilizados por anunciantes;</p>
              <p className={classes.textParagraph}>h) Recolhimento de dados no âmbito do atendimento ao espectador e ouvinte, tendo em vista o esclarecimento de dúvidas e questões e o recebimento de sugestões;</p>
              <p className={classes.textParagraph}>i) Recolhimento de dados para possibilitar o envio de alertas e notificações de serviços subscritos pelos utilizadores;</p>
              <p className={classes.textParagraph}>j) Cobrança extrajudicial, judicial e pagamentos;</p>
              <p className={classes.textParagraph}>l) Gestão de respostas técnicas e reclamações; </p>
              <p className={classes.textParagraph}>m) Consulta de conteúdos audiovisuais e cinematográficos, registro para solicitação de cópias de conteúdo, registro de pedidos de licenciamento de conteúdos e gestão de dados para faturamento;</p>
              <p className={classes.textParagraph}>n) Recolhimento de dados através do preenchimento de formulários online para submissão de pedidos de inscrição em concursos, promoções comerciais, ou projetos organizados pela NSC;</p>
              <p className={classes.textParagraph}>o) Registro e gestão de convidados em eventos e programas da NSC;</p>
              <p className={classes.textParagraph}>p) Contatos com os participantes vencedores dos concursos e projetos para entrega de prêmios.</p>
            </ul>

            <h5 className={classes.bulletTitle}>5. Com quem a NSC Comunicação compartilha os seus dados pessoais?</h5>
            <p className={classes.textParagraph}>O compartilhamento de dados a terceiros é realizado pela NSC Comunicação conforme a legislação vigente, e dentro dos limites das finalidades e fundamentos jurídicos definidos nesta Política de Privacidade. </p>
            <ul>
              <p className={classes.textParagraph}>a) pessoas que prestam seus serviços à NSC Comunicação (por exemplo, prestadores de serviços de Tecnologia da Informação);</p>
              <p className={classes.textParagraph}>b) empresas que integram a NSC Comunicação;</p>
              <p className={classes.textParagraph}>c) entidades parceiras, nos casos em que tiver prestado o seu consentimento para esse efeito, ou que tal for necessário à prestação do serviço que você tenha contratado, ou ainda, nas situações de legítimo interesse;</p>
              <p className={classes.textParagraph}>d) autoridades públicas, ou no cumprimento de obrigações da NSC Comunicação.</p>
            </ul>

            <h5 className={classes.bulletTitle}>6. Quais os direitos dos titulares de dados pessoais?</h5>
            <p className={classes.textParagraph}>O titular tem direito ao acesso facilitado às informações sobre o tratamento de seus dados, que deverão ser disponibilizadas de forma clara, adequada e ostensiva. O titular dos dados pessoais tem direito a obter, a qualquer momento e mediante requisição:</p>
            <ul>
              <p className={classes.textParagraph}>a) A confirmação da existência de tratamento;</p>
              <p className={classes.textParagraph}>b) O acesso aos dados; a correção de dados incompletos, inexatos ou desatualizados;</p>
              <p className={classes.textParagraph}>c) A anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na legislação;</p>
              <p className={classes.textParagraph}>d) A portabilidade dos dados a outro fornecedor de serviço ou produto (exceto dados anonimizados), mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;</p>
              <p className={classes.textParagraph}>e) Informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;</p>
              <p className={classes.textParagraph}>f) Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</p>
              <p className={classes.textParagraph}>g) Revogação do consentimento nos termos da legislação aplicável; peticionar em relação a seus dados; </p>
              <p className={classes.textParagraph}>h) A eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas seguintes hipóteses: </p>
              <ul>
                <p className={classes.textParagraph}>i) cumprimento de obrigação legal ou regulatória pela NSC Comunicação;</p>
                <p className={classes.textParagraph}>ii) estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais;</p>
                <p className={classes.textParagraph}>iii) transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos na legislação;</p>
                <p className={classes.textParagraph}>iv) uso exclusivo da NSC Comunicação e desde que anonimizados.</p>
              </ul>
            </ul>

            <h5 className={classes.bulletTitle}>7. Como o titular do dado pode exercer os seus direitos?</h5>
            <p className={classes.textParagraph}>O titular dos dados pessoais poderá a qualquer momento exercer os seus direitos através dos seguintes endereços:</p>
            <p className={classes.textParagraph}>Endereço: Rua General Vieira da Rosa, 1570, Morro do Antão, Florianópolis/SC (CEP 88020-420). Telefone: 48-3216.3366 ou 47-3221.2020</p>
            <p className={classes.textParagraph}>Email: atendimento.nsc@somosnsc.com.br </p>

            <h5 className={classes.bulletTitle}>8. Por quanto tempo a NSC Comunicação guarda os seus dados pessoais? </h5>
            <p className={classes.textParagraph}>A NSC Comunicação apenas tratará e conservará os dados pessoais do Utilizador pelo período de tempo estritamente necessário para a realização das finalidades indicadas nesta Política de Privacidade.</p>
            <p className={classes.textParagraph}>Os dados pessoais serão tratados e conservados durante a vigência da relação contratual entre a NSC Comunicação e seus Utilizadores, pelo período de tempo que resulte de consentimento prestado pelo titular dos dados ou pelo período de tempo necessário para salvaguardar um interesse legítimo da NSC Comunicação, ou de terceiro.</p>
            <p className={classes.textParagraph}>Após o decurso do prazo de conservação e desde que a NSC Comunicação não esteja obrigada, por imposição legal judicial ou administrativa, a proceder à sua conservação, a NSC Comunicação eliminará os dados pessoais ou procederá à sua anonimização.</p>

            <h5 className={classes.bulletTitle}>9. Crianças e adolescentes</h5>
            <p className={classes.textParagraph}>Crianças e adolescentes não podem usar os serviços disponíveis no site sem a autorização de seus pais, responsáveis ​​ou representantes legais, que serão os únicos responsáveis ​​por todos os atos praticados pelos menores durante a navegação nos sites ou disponibilização de dados pessoais por qualquer meio. Nesse sentido e na medida em que a NSC Comunicação não tem a capacidade de controlar se os usuários são ou não crianças ou adolescentes, devem os pais e responsáveis estar atentos aos mecanismos necessários para impedir que estes acessem e/ou facilitem dados pessoais sem a supervisão dos pais e responsáveis, não aceitando a NSC Comunicação qualquer responsabilidade a esse respeito.</p>

            <h5 className={classes.bulletTitle}>10. Sites de terceiros e uso de cookies</h5>
            <p className={classes.textParagraph}>A NSC Comunicação não é responsável pelos sites externos ou de terceiros, que possam ser acessados a partir dos sites da NSC Comunicação, nem por qualquer conteúdo publicado nos mesmos por quaisquer terceiros, por isso a NSC Comunicação aconselha aos Utilizadores que ao acessar sites externos ou de terceiros verifiquem os respetivos termos e condições de utilização e política de privacidade. A NSC Comunicação utiliza Cookies nos seus sites e, por isso, é aconselhável que consulte a Política de Cookies da NSC Comunicação.</p>

            <h5 className={classes.bulletTitle}>11. Alterações da Política de Privacidade</h5>
            <p className={classes.textParagraph}>A NSC Comunicação reserva-se no direito de alterar essa Política de Privacidade sempre que necessário, com o objetivo de fornecer maior segurança e praticidade aos Utilizadores, e tais alterações entram em vigor a partir da data da sua publicidade ou comunicação aos Utilizadores.</p>

            <h3 className={classes.mainTitle}>Política de Cookies</h3>

            <h5 className={classes.bulletTitle}>1. Cookies: o que são?</h5>
            <p className={classes.textParagraph}>As cookies são arquivos que se armazenam no computador do usuário, aqui também chamado de Utilizador, que navega através de Internet e que, em particular, contém um número que permite identificar o computador do usuário. A colocação de cookies ajudará o site a reconhecer o seu dispositivo numa próxima visita. Usamos o termo cookies nesta política para referir todos os arquivos que recolhem informações desta forma.</p>
            <p className={classes.textParagraph}>A utilização deste tipo de tecnologia no acesso a Sites é uma prática comum, não prejudica os equipamentos dos usuários (computador, smartphone, tablet, etc.) e os diferentes browsers (navegadores) permitem que cada Utilizador tenha a possibilidade de recusar e/ou de eliminar os cookies que já foram armazenados. Os cookies utilizados não recolhem informação que identifica o usuário, no entanto, se já for Utilizador da NSC Comunicação poderemos monitorar as suas visitas ao site desde que, pelo menos, por uma vez, tenha iniciado a sua navegação a partir de ação voluntária do usuário.</p>

            <h5 className={classes.bulletTitle}>2. Por que utilizamos cookies?</h5>
            <p className={classes.textParagraph}>Os sites da NSC Comunicação armazenam cookies que são utilizados para melhorar o desempenho e a experiência dos Utilizadores.</p>
            <p className={classes.textParagraph}>Os cookies armazenados identificam o computador utilizado e permitem melhorar a sua experiência de navegação e utilização, por exemplo, ajudando-nos a associar o seu equipamento às suas preferências ou fornecer-lhe conteúdos que entendemos que podem ser do seu interesse, ou ainda para a liberação de conteúdos exclusivos para assinantes.</p>
            <p className={classes.textParagraph}>A NSC Comunicação não assume nenhuma responsabilidade pelo conteúdo das políticas de cookies e de dados de terceiros que possam ser acessadas a partir de navegação inicial nos seus sites.</p>
            <p className={classes.textParagraph}>Se você ainda estiver com dúvida pode visitar a nossa Política de Privacidade de Dados.</p>

            <h5 className={classes.bulletTitle}>3. Alterações da Política de Cookies</h5>
            <p className={classes.textParagraph}>A NSC Comunicação reserva-se no direito de alterar essa Política de Cookies sempre que necessário, com o objetivo de fornecer maior segurança e praticidade aos Utilizadores, e tais alterações entram em vigor a partir da data da sua publicidade ou comunicação aos Utilizadores.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PoliticasDeUso);
