import React from 'react';

export function FormatDate(date) {
  var datePart = date.match(/\d+/g),
    year = datePart[0],
    month = datePart[1],
    day = datePart[2]

  return day + '/' + month + '/' + year;
}

export function FormatInvertDateTime(date) {
  var datePart = date.match(/\d+/g),
    day = datePart[0],
    year = datePart[2],
    month = datePart[1]

  return year + '-' + month + '-' + day
}

export function FormatCpfCnpj(item) {
  if (!item) {
    return '';
  }

  let cpfCnpj = '';
  if (item.length > 11) {
    cpfCnpj = item.substring(0, 2) + '.' + item.substring(2, 5) + '.' + item.substring(5, 8) + '/' + item.substring(8, 12) + '-' + item.substring(12, 14)
  } else {
    cpfCnpj = item.substring(0, 3) + '.' + item.substring(3, 6) + '.' + item.substring(6, 9) + '-' + item.substring(9, 11)
  }

  return cpfCnpj;
}


export function RemoveCountryCode(phone) {
  let retPhone = phone;
  if (retPhone.substring(0, 3) === '+55') {
    retPhone = retPhone.substring(3, retPhone.length);
  }

  return retPhone;
}

export function IsValidDate(dateString) {
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
    return false;

  // Parse the date parts to integers
  var parts = dateString.split("/");
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10);
  var year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12)
    return false;

  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
    monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
};

export function getEnv(name, { required = true, defaultValue = undefined } = {}) {
  const value = process.env[name];

  if (value) {
    return value;
  }

  if (required) {
    throw new Error(`Missing environment variable "${name}"`);
  }

  return defaultValue;
}

export class ErrorMessages {
  static getTitle(code) {
    switch (code) {
      case 'SUBSCRIPTION_ALREADY_EXISTS': {
        return `Você já é um assinante NSC Total!`;
      }
      case 'PENDING_PAYMENT': {
        return `Erro no pagamento!`;
      }
      case 'PENDING_SUBSCRIPTION_EXISTS':
      case 'ADDRESS_NOT_ACCEPTED':
      case 'DELIVERY_MODE_NOT_ACCEPTED':
      case 'SUBSCRIPTION_ATTEMPTS_EXCEEDED':
        return `Estamos processando sua compra.`;
      default:
        return 'Ocorreu um erro.';
    }
  }

  static getMessage(code) {
    switch (code) {
      case 'SUBSCRIPTION_ATTEMPTS_EXCEEDED': {
        return [`Por favor, aguarde ou entre em contato conosco pelos `, <a href={getEnv('REACT_APP_USUARIONSC_HELP')} style={{cursor: 'pointer', color: '#f66917', textDecoration: 'underline'}}>canais de atendimento</a>, `.`];
      }
      case 'SUBSCRIPTION_ALREADY_EXISTS': {
        return `Confira as principais notícias do dia.`;
      }
      case 'PENDING_PAYMENT': {
        return `Segundo sua administradora ocorreu algum erro no pagamento. Tente novamente ou altere o cartão.`;
      }
      case 'ADDRESS_NOT_ACCEPTED':
      case 'DELIVERY_MODE_NOT_ACCEPTED':
      case 'PENDING_SUBSCRIPTION_EXISTS':
        return [`Por favor, aguarde ou entre em contato conosco`, <br></br>, `pelos `, <a href={getEnv('REACT_APP_USUARIONSC_HELP')} style={{cursor: 'pointer', color: '#f66917', textDecoration: 'underline'}}>canais de atendimento</a>, `.`];
      default: {
        return `Entraremos em contato assim que possível. Se preferir, entre em contato conosco pelos canais de atendimento.`;
      }
    }
  }
}

