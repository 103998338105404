import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Icon from '../../img/check_icon.svg';
import "./Stepper.css";

const styles = theme => ({
  connectorRoot: {
    width: '100%',
    left: 'calc(-50% + 10px)',
    top: '7px',
    '@media (max-width: 720px)': {
      top: '3px',
    },
    '@media (max-width: 420px)': {
      width: '70%',
      marginLeft: '-8px'
    }
  },
  connectorActive: {
    '& $connectorLine': {
      borderColor: '#FF6915',
      marginTop: '8px',
    },
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: '#FF6915',
      marginTop: '8px',
    },
  },
  connectorDisabled: {
    '& $connectorLine': {
      borderColor: '#d8d4d4',
      marginTop: '8px',
    },
  },
  connectorLine: {
    transition: theme.transitions.create('border-color'),
    borderTopWidth: '4px',
    borderColor: '#d8d4d4'
  },
  iconDisabled: {
    width: '33px',
    height: '33px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    border: '3px #d8d4d4 solid',
    color: '#b5b5b5',
    zIndex: 2,
    '@media (max-width: 720px)': {
      width: '25px',
      height: '25px'
    },
  },
  iconActive: {
    width: '33px',
    height: '33px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    border: '3px solid #FF6915',
    color: '#FF6915',
    zIndex: 2,
    '@media (max-width: 720px)': {
      width: '25px',
      height: '25px'
    },
  },
  iconCompleted: {
    backgroundColor: '#FF6915'
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.3rem',
    fontFamily: 'Source Sans Pro, sans-serif',
    color: '#b5b5b5',
    marginTop: '3px !important',
    '@media (max-width: 720px)': {
      fontSize: '12px'
    },
    '@media (max-width: 380px)': {
      fontSize: '12px'
    },
    '@media (max-width: 320px)':
    {
      fontSize: '10px'
    }
  },
  labelActive: {
    fontStyle: 'normal',
    fontWeight: 'bold !important',
    fontFamily: 'Source Sans Pro, sans-serif',
    color: '#FF6915 !important'
  },
  labelCompleted: {
    fontStyle: 'normal',
    fontWeight: 'bold !important',
    fontFamily: 'Source Sans Pro, sans-serif',
    color: '#FF6915 !important'
  },
  stepLabel: {
    '@media (max-width: 420px)': {
      marginLeft: '-20px',
      marginRight: '-5px',
     // fontSize: '1.8rem',
    }
  }
});

class AuthStepper extends Component {
  constructor(props) {
    super(props);
    this.activeStep = props.activeStep
    this.steps = 4;
  }

  render() {
    const { classes } = this.props;

    const connector = (
      <StepConnector
        classes={{
          active: classes.connectorActive,
          completed: classes.connectorCompleted,
          disabled: classes.connectorDisabled,
          line: classes.connectorLine,
          root: classes.connectorRoot
        }}
      />
    );

    const steppers = [
      'Identificação',
      'Pagamento',
      'Conclusão'
    ];

    const renderStepIcon = (index) => {
      const renderIcon = (index, showIcon = false) => {
        return (
          <span className='icon-step'>
            {showIcon ?
              <img src={Icon} alt="" /> :
              ++index}
          </span>
        );
      };

      const icons = [...Array(this.steps).keys()].map(() => renderIcon(index, (index < this.activeStep)));

      return {
        icon: icons[index],
        classes: {
          iconContainer: this.activeStep >= index ?
            `${classes.iconActive} ${(index < this.activeStep ? classes.iconCompleted : null)}` :
            classes.iconDisabled,
          label: classes.label,
          active: classes.labelActive,
          completed: classes.labelCompleted
        }
      }
    }

    const renderStep = (label, index) => {
      return (
        <Step key={label}>
          <StepLabel className={classes.stepLabel}  {...renderStepIcon(index)}>{label}</StepLabel>
        </Step>
      );
    }

    const shouldRenderStep = (index) => {
      if (this.props.renderOnlyThisStep)
        return (this.props.activeStep - 1) === index;
      return true;
    };

    return (
      <Stepper
        alternativeLabel
        activeStep={this.activeStep}
        connector={connector}
        style={{
          backgroundColor: 'transparent',
          padding: '8px'
        }}>
        {steppers.map((label, index) => (shouldRenderStep(index) && renderStep(label, index)))}
      </Stepper>
    );
  }
}

AuthStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(AuthStepper);

