import React, { Component } from 'react';
import AppRouter from './Router'
import TagManagerService from './Services/TagManagerService';

class App extends Component {

  async componentDidMount() {
    await TagManagerService.initialize();
  }

  render() {
    return (
      <AppRouter />
    );
  }
}

export default App;
