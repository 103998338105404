import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import UrlHelper from '../Helpers/UrlHelper';

const styles = theme => ({
  divCentral: {
    margin: 'auto',
  },
  boxParabens: {
    margin: '4% auto',
    width: '50%',
    padding: '2%',
    boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.5)',
    borderRadius: '10px',
    '& p:nth-of-type(1)': {
      fontWeight: 'bold',
      color: '#FF6915',
      fontSize: '2.5rem',
      margin: 'auto',

    },
    '& p:nth-of-type(2)': {
      fontSize: '1.4rem',
      textAlign: 'center',
      color: '#4D4D4D',
      padding: '2% 0 2%',
      margin: 'auto',
      width: '90%'
    },
    '@media (max-width: 750px)': {
      width: '85%'
    },
    '@media (max-width: 550px)': {
      '& p:nth-of-type(1)': {
        fontSize: '2.4rem',
        padding: '1% 0 2%',
      },
      '& p:nth-of-type(2)': {
        fontSize: '1rem',
        padding: '4% 0 8%',
      }
    }
  },
  divider: {
    backgroundColor: '#BEBEBE',
    height: '.105vw',
    border: 'none',
    width: '90%'
  },
  btnContinuar: {
    height: '46px',
    border: 'none',
    background: '#EE6B25',
    fontSize: '1.5rem',
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '10px',
    cursor: 'pointer',
    padding: '0 46px'
  },
  divTextContent: {
    margin: '5% auto',
    fontWeight: 'bold',
    fontSize: '2.1rem',
    textAlign: 'center',
    color: '#FF6915',
    width: '55%',
    '@media (max-width: 550px)': {
      fontSize: '1.6rem',
      padding: '1% 0 2%',
      width: '75%',
    }
  },
 
});

class ConclusaoParabens extends Component {

  constructor(props) {
    super(props);

    this.handleBrandCardClick = this.handleBrandCardClick.bind(this);

    this.state = {
      products: {
        dc: 'dc',
        an: 'an',
        santa: 'santa',
        hora: 'hora-sc',
        cbn: 'cbn',
        itapema: 'https://www.itapemafm.com.br/',
        atlantida: 'http://atl.clicrbs.com.br/#!/home'
      }
    }
  }

  handleBrandCardClick(event) {
    const product = this.state.products[event.currentTarget.getAttribute('id')];
    window.open(product.indexOf('http') !== -1 || product.indexOf('https') !== -1 ?
      product :
      UrlHelper.buildRedirectUrl(product), '_blank');
  }

  handleRedirect() {
    const urlState = UrlHelper.getQueryString('url_state');
    if (urlState) {  
      const redirectUrl = UrlHelper.buildRedirectUrl(urlState);
      window.location.href = redirectUrl;
      console.log('redirectUrl : '+ redirectUrl);
      console.log('urlState : '+ urlState);
    }   else{
          const redirectUrl = UrlHelper.buidNscTotalLoginRedirectUrl();
          window.location.href = redirectUrl;
          console.log('redirectUrl : '+ redirectUrl);
        } 
  }

  render() {
    const { classes, message, title, message2, link } = this.props;
    console.log('props : '+Object.keys(this.props));
  
    console.log('classes : '+ Object.keys(classes));

    
    const buttonText = () => {
      if (this.props.errorCode === 'SUBSCRIPTION_ALREADY_EXISTS')
        return 'Voltar ao NSC Total';
      if (this.props.errorCode)
        return 'Voltar';
      return 'continuar';
    };

    let footer = (
      <div>
        <div>
          <button onClick={this.handleRedirect} className={classes.btnContinuar} type="button" >
            {buttonText()}
          </button>
        </div>

        <div className={classes.divTextContent}>
         {(this.props.errorCode === 'INTERNAL_ERROR') ? '' : ' Aproveite!'} <br />
          
          {(this.props.errorCode === 'INTERNAL_ERROR') ? '' : message2 ? message2 : 'Agora você tem acesso a todos os conteúdos do NSC Total' }
        </div>

      </div>
    )

    if (this.props.noFooter) {
      footer = null;
    }

    return (
      <div className={classes.divCentral}>
        <div className={classes.boxParabens}>
            <p>{title}</p>
            {!!title && <hr className={classes.divider} />}
            <p>
              {message} {link}
            </p>
          </div>

          {footer}

        </div>
    )
  }
}

export default withStyles(styles)(ConclusaoParabens);
