import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Carousel from 'react-elastic-carousel';

const styles = theme => ({
  divCentral: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 330px)': {
      display: 'block',
      minWidth: '300px'
    }
  },
  divCentralCousel: {
    width: '50%',
    margin: '2% auto',
    '& button:hover': {
      backgroundColor: '#FF6915 !important'
    },
    '& button': {
      borderRadius: '10%',
      height: '70px',
      width: '32px',
      minWidth: '32px',
      boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.5)',
      backgroundColor: '#fff',
      color: '#D8D8D8',
      fontSize: '2.5em'
    },
    '@media (max-width: 1500px)': {
      width: '75%'
    },
    '@media (max-width: 950px)': {
      width: '80%'
    },
    '@media (max-width: 850px)': {
      width: '90%'
    },
    '@media (max-width: 800px)': {
      width: '100%'
    },
    '@media (max-width: 750px)': {
      width: '80%'
    },
    '@media (max-width: 650px)': {
      width: '90%'
    }
  },
  carouselItem: {
    width: '297px',
    height: '440px',
    '& img': {
      borderRadius: '10px',
      border: '2px solid rgba(225, 224, 224, 0.31)'
    }
  }
});

class CourselApp extends Component {

  constructor(props) {
    super(props);
    this.state = { carouselLength: 3 };

    this.handleResizeCarousel = this.handleResizeCarousel.bind(this);
  }

  componentDidMount() {
    this.handleResizeCarousel();
    window.addEventListener('resize', this.handleResizeCarousel);
  }

  handleResizeCarousel() {
    if (window.innerWidth > 750)
      this.setState({ carouselLength: 3 });

    if (window.innerWidth > 600 && window.innerWidth <= 750)
      this.setState({ carouselLength: 2 });

    if (window.innerWidth <= 600)
      this.setState({ carouselLength: 1 });
  }

  render() {
    const { classes, images } = this.props;
    return (
      <div className={classes.divCentral}>
        <div className={classes.divCentralCousel}>
          <Carousel itemsToScroll={1} itemPadding={[2]} itemsToShow={this.state.carouselLength} pagination={false}>
            {images.map(item =>
              <div className={classes.carouselItem} key={item.id}>
                <img src={item.img} alt="" />
              </div>
            )}
          </Carousel>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CourselApp);
