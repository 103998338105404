import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import imgDispositivos from '../img/dispositivos-conclusao.png'

const styles = theme => ({
  tituloPrincipal: {
    fontWeight: 'bold',
    color: '#EE6B25',
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: 36,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 58,
    },
  },
  tituloSecundario: {
    marginTop: 10,
    color: '#4D4D4D',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 30,
      fontSize: 18,
      fontWeight: 'bold',
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: 10,
      fontSize: 32,
      lineHeight: '36px',
      fontWeight: 'normal',
    },
  },
  parConclusao: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 24,
    },
    color: '#4D4D4D',
  },
  imgDispositivos: {
    [theme.breakpoints.down('xs')]: {
      // maxWidth: 267
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      // maxWidth: 340
      width: '100%'
    },
  },
});

class ConclusaoSucesso extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <div>
        <h3 className={classes.tituloPrincipal}>Parabéns!</h3>
        <h1 className={classes.tituloSecundario}>Agora você é um assinante NSC Total + Clube NSC. Você tem<br />acesso ilimitado ao melhor conteúdo de Santa Catarina</h1>
        <img className={classes.imgDispositivos} src={imgDispositivos} alt="Dispositivos" />
      </div>
    );
  }
}

export default withStyles(styles)(ConclusaoSucesso);
