import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import imgLogo from '../img/globo-mais-cor.png';
import imgPhone from '../img/smartphone-globo-mais.png';
import imgLogos from '../img/logos-globo-mais-nsc.png';

const styles = theme => ({
  divBody: {
    width: '100%',
  },
  divTitle: {
    width: '100%',
    height: '110px',
    background: 'rgb(255,255,255)',
    background: 'linear-gradient(90deg, rgba(255,255,255,1) 12%, rgba(229,77,122,1) 39%, rgba(248,63,55,1) 62%)',
    '@media (max-width: 750px)': {
      height: '250px',
      background: 'linear-gradient(90deg, rgba(255,255,255,1) 12%, rgba(229,77,122,1) 64%, rgba(248,63,55,1) 79%)',
    },
  },
  titleImg: {
    width: '200px',
    float: 'left',
    marginTop: '32px',
    marginLeft: '32px'
  },
  titleText: {
    float: 'right',
    color: '#fff',
    width: '500px',
    marginRight: '10px',
    '@media (max-width: 750px)': {
      color: '#000',
      marginTop: '20px',
      width: '100%',
    },
  },
  textSpan: {
    width: '100%',
    float: 'right',
    textAlign: 'right',
    fontSize: '24px',
    fontWeight: '600'
  },
  textSpanMonths: {
    width: '100%',
    float: 'right',
    textAlign: 'right',
    marginTop: '10px'
  },
  divContent: {
    height: '500px',
    '@media (max-width: 750px)': {
      height: '800px'
    },
  },
  smartphoneImg: {
    width: '15%',
    marginLeft: '25%',
    float: 'left',
    marginTop: '50px',
    '@media (max-width: 750px)': {
      width: '70%',
      marginLeft: '15%',
    },
  },
  logosImg: {
    width: '40%',
    float: 'right',
    marginRight: '20%',
    marginTop: '50px',
    '@media (max-width: 750px)': {
      width: '80%',
      marginRight: '10%',
    },
  },
  contentText1: {
    marginTop: '50px',
    float: 'left',
    width: '100%',
    color: '#f83f37',
    fontSize: '30px',
    fontWeight: '600',
    lineHeight: '27px'
  },
  contentText: {
    float: 'left',
    width: '100%',
    color: '#f83f37',
    fontSize: '30px',
    fontWeight: '600',
    lineHeight: '27px'
  },
  contentLink: {
    cursor: 'pointer'
  },
  contentButton: {
    cursor: 'pointer',
    backgroundColor: '#479a12',
    height: '60px',
    border: 'none',
    color: '#fff',
    fontWeight: '600',
    fontSize: '35px',
    width: '280px',
    borderRadius: '30px',
    marginTop: '43px',
  }
});

class AppGlobo extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.divBody} id="globomais">
        <div className={classes.divTitle}>
          <img className={classes.titleImg} src={imgLogo} />
          <div className={classes.titleText}>
            <span className={classes.textSpan}>Acesso ao conteúdo digital</span> 
            <span className={classes.textSpan}>de grande publicações da Editora Globo</span>
            <span className={classes.textSpanMonths}>Por três meses.</span>
          </div>
        </div>
        <div className={classes.divContent}>
          <img className={classes.smartphoneImg} src={imgPhone}/>
          <img className={classes.logosImg} src={imgLogos}/>
          <span className={classes.contentText1}>Acesse o portal Globo+, faça seu login e utilize</span>
          <span className={classes.contentText}>o voucher para validar seu acesso por três meses.</span>
          <span className={classes.contentText}>Os dados foram enviados para seu e-mail.</span>
          <a className={classes.contentLink} href="https://assinaturaglobo.globo.com/globo-mais/vitrine/nsc" target="_blank"><button className={classes.contentButton}>Acessar Globo+</button></a>
        </div>
      </div>
    )
  }
}
  
export default withStyles(styles)(AppGlobo);
