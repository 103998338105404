import ApiService from './ApiService';
import NSCAuthService from './NSCAuthService';

class UserSession {

  static getUserData = async () => {
    try {
      const user = await ApiService.getUserSessao();

      if (user.status === 200) {
        return {
          name: user.user.given_name,
          isLoggedIn: true,
          user: user.user
        };
      }
    } catch (ex) { }

    return {
      name: '',
      isLoggedIn: false,
      user: {}
    };
  }

  static getUser = async () => {
    const user = await this.getUserData();
    if (user.isLoggedIn) {
      return user.user;
    }

    const resp = await NSCAuthService.createCode();
    if (resp.status === 'AUTHENTICATED') {
      await ApiService.login(resp.code);
      return await this.getUserData().user;
    }

    return null;
  }
}

export default UserSession;
