import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import imgDispositivos from '../img/dispositivos-conclusao.png'

const styles = theme => ({
  tituloPrincipal: {
    fontWeight: 'bold',
    color: '#EE6B25',
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: 36,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 58,
    },
  },
  tituloSecundario: {
    marginTop: 10,
    color: '#4D4D4D',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
      fontSize: 20,
      fontWeight: 'bold',
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: 10,
      fontSize: 32,
      lineHeight: '36px',
      fontWeight: 'normal',
    },
  },
  parConclusao: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 24,
    },
    color: '#4D4D4D',
  },
  imgDispositivos: {
    [theme.breakpoints.down('xs')]: {
      // maxWidth: 267
      width: '100%'
    },
    [theme.breakpoints.up('sm')]: {
      // maxWidth: 340
      width: '100%'
    },
  },
});

class ConclusaoSucesso extends React.Component {

  getMensageFromError(err) {
    const arrErrors = [];
    arrErrors['SUBSCRIPTION_ATTEMPTS_EXCEEDED'] = 'Estamos processando a sua compra. Por favor, aguarde ou entre em contato conosco através dos canais de atendimento.';
    arrErrors['SUBSCRIPTION_ALREADY_EXISTS'] = 'Identificamos que você já possui uma assinatura ativa na NSC. Entre com seu login no NSC Total ou entre em contato conosco pelos canais de atendimento.';
    arrErrors['PENDING_SUBSCRIPTION_EXISTS'] = 'Identificamos que você já possui uma solicitação de assinatura. Por favor, entre em contato pelos canais de atendimento para ativar sua assinatura.';
    arrErrors['INTERNAL_ERROR'] = 'Estamos processando a sua compra. Por favor, aguarde ou entre em contato conosco através dos canais de atendimento.';
    arrErrors['PENDING_PAYMENT'] = arrErrors['INTERNAL_ERROR'];
    arrErrors['INVALID_FORMAT'] = arrErrors['INTERNAL_ERROR'];
    arrErrors['ORDER_DUPLICATED'] = arrErrors['INTERNAL_ERROR'];

    return !arrErrors[this.props.error_code] ? arrErrors['INTERNAL_ERROR'] : arrErrors[this.props.error_code];
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <br />
        <h3 className={classes.tituloSecundario}>
          {this.getMensageFromError(this.props.error_code)}
        </h3>
        <p className={classes.infoContato}>Telefone: 0800 644 4001</p>
        <p className={classes.infoContato}>E-mail: lojansc@somosnsc.com.br</p>
        <img className={classes.imgDispositivos} src={imgDispositivos} alt="Dispositivos" />
        <br /><br />
      </div>
    );
  }
}

export default withStyles(styles)(ConclusaoSucesso);
