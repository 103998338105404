import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import imagemClubNsc from '../img/logo_clube_nsc.png';
import imageClubWhite from '../img/NSC_clube_white.png'
import buttonAppGoogleNsc from '../img/button_google_play.png';
import buttonAppStoreNsc from '../img/button_app_store.png';

import clubeImg1 from '../img/clube-nsc-app/clube-img1.jpg';
import clubeImg2 from '../img/clube-nsc-app/clube-img2.jpg';
import clubeImg3 from '../img/clube-nsc-app/clube-img3.jpg';
import clubeImg4 from '../img/clube-nsc-app/clube-img4.jpg';
import CourselNscTotal from './CourselApp';


const styles = theme => ({
  divAplicativoNoticia: {
    width: '100%',
    height: '6rem',
    marginBottom: '20px',
    background: 'linear-gradient(90deg, #FFFFFF 10.67%, rgba(255, 255, 255, 0) 55.36%), linear-gradient(270deg, rgba(40, 35, 120, 0) 7.8%, #5C2D91 97.81%), #DA1C5C',
    '& img': {
      width: '4.5rem',
      float: 'left',
      margin: '25px'
    },
    '& #mobile': {
      display: 'none'
    },
    '@media (max-width: 750px)': {
      height: '8rem',
      background: 'linear-gradient(360deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 61.82%), linear-gradient(270deg, rgba(40, 35, 120, 0) 7.81%, #5C2D91 100%), #DA1C5C',
      '& #desk': {
        display: 'none'
      },
      '& #mobile': {
        display: 'block',
        width: '10%',
        float: 'right',
        marginLeft: '0'
      }
    }
  },
  nscTotalInfo: {
    float: 'right',
    textAlign: 'right',
    color: '#FFFFFF',
    margin: '10px 25px auto',
    '& p:nth-of-type(1)': {
      fontWeight: 'bold',
      fontSize: '1.5rem',
      lineHeight: '1.4rem',
      margin: '0'
    },
    '& p:nth-of-type(2)': {
      fontSize: '1.2rem',
      margin: '6px auto'
    },
    '@media (max-width: 750px)': {
      float: 'left',
      textAlign: 'left',
      margin: '16px 25px auto',
      '& p:nth-of-type(1)': {
        fontSize: '1.2rem',
        lineHeight: '1.1rem'
      },
      '& p:nth-of-type(2)': {
        fontSize: '1rem',
      }
    },
    '@media (max-width: 380px)': {
      width: '60%',
      '& p:nth-of-type(1)': {
        fontSize: '.9rem',
        lineHeight: '1rem'
      },
      '& p:nth-of-type(2)': {
        fontSize: '.7rem',
      }
    }
  },
  divAppNsc: {
    margin: 'auto',
    display: 'inline-flex',
    '& img': {
      width: '100%'
    }
  },
  invertBackground: {
    background: 'linear-gradient(270deg, #FFFFFF 13.22%, rgba(255, 255, 255, 0) 70.63%), #DA1C5C',
    '& img': {
      float: 'right'
    },
    '@media (max-width: 750px)': {
      background: 'linear-gradient(360deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 61.82%), linear-gradient(270deg, rgba(40, 35, 120, 0) 7.81%, #5C2D91 100%), #DA1C5C',
      '& img': {
        float: 'left'
      }
    }
  },
  invertText: {
    float: 'left',
    textAlign: 'left'
  },
  buttonApp: {
    background: 'none',
    border: 'none',
    cursor: 'pointer'
  }
});

class AppClubeNsc extends Component {

  constructor(props) {
    super(props);

    this.handleGoToStore = this.handleGoToStore.bind(this);

    this.state = {
      store: {
        android: 'https://play.google.com/store/apps/details?id=br.com.nsccomunicacao.clubensc',
        ios: 'https://apps.apple.com/br/app/clube-nsc/id1451592115'
      }
    };
  }

  handleGoToStore(event) {
    const store = event.currentTarget.getAttribute('id');
    window.location.href = store.indexOf('ios') !== -1 ? this.state.store.ios : this.state.store.android;
  }

  render() {

    const { classes, invertBackground } = this.props;

    const img = [
      { id: 1, img: clubeImg1 },
      { id: 2, img: clubeImg2 },
      { id: 3, img: clubeImg3 },
      { id: 4, img: clubeImg4 }
    ];

    return (
      <div>
        <div className={`${classes.divAplicativoNoticia} ${invertBackground && classes.invertBackground}`}>
          <img src={imagemClubNsc} id="desk" alt="" />
          <img src={imageClubWhite} id="mobile" alt="" />
          <div className={`${classes.nscTotalInfo} ${invertBackground && classes.invertText}`}>
            <p>Descontos em mais de 500 <br /> parceiros em Santa Catarina</p>
            <p>O seu aplicativo de benefícios</p>
          </div>
        </div>

        <CourselNscTotal images={img} />

        <div className={classes.divAppNsc}>
          <button onClick={this.handleGoToStore} className={classes.buttonApp} id="app-clube-and">
            <img src={buttonAppGoogleNsc} alt="" />
          </button>
          <button onClick={this.handleGoToStore} className={classes.buttonApp} id="app-clube-ios">
            <img src={buttonAppStoreNsc} alt="" />
          </button>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(AppClubeNsc);
