
import { getEnv } from '../Helpers/Utils.js';

const BASE_URL = getEnv('REACT_APP_API');

class ApiService {

  static async getUserSessao() {
    const URL = BASE_URL + 'loja/getUserInfo';
    //const username = 'assine';
    //const password = 'nsc';
    const ret = await fetch(URL, {
      method: 'get',
      credentials: 'include',
    //  headers: {'Authorization': 'Basic ' + Buffer.from(username + ":" + password).toString('base64')}
    })
      .then(async response => {
        return response.json();
      })
      .then(responseJson => {
        return responseJson
      });

    return ret
  }

  static async updateUserFlags(objUser) {
    const URL = BASE_URL + 'loja/updateUserFlags';
  //  const username = 'assine';
  //  const password = 'nsc';
    const ret = await fetch(URL, {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
   //     'Authorization': 'Basic ' + Buffer.from(username + ":" + password).toString('base64')
      },
      body: JSON.stringify(objUser),
    })
      .then(async response => {
        if (response.status === 500) {
          return { status: 500, message: 'Ocorreu um erro interno, por favor entre em contato conosco' }
        }
        return response.json();
      })
      .then(responseJson => {
        return responseJson
      });

    return ret
  }

  static async order(objUser) {
    const URL = BASE_URL + 'loja/order';
  //  const username = 'assine';
  //  const password = 'nsc';
    const ret = await fetch(URL, {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
   //     'Authorization': 'Basic ' + Buffer.from(username + ":" + password).toString('base64')
      },
      body: JSON.stringify(objUser),
    })
      .then(async response => {
        return response.json();
      })
      .then(responseJson => {
        return responseJson
      }).catch(err => {
        return {
          status: 400,
          as_error: "PENDING_PAYMENT"
        };
      });

    return ret
  }

  static async login(code) {
    const URL = BASE_URL + 'auth/authorize/' + code;
  //  const username = 'assine';
  //  const password = 'nsc';
    const ret = await fetch(URL, {
      method: 'post',
      credentials: 'include',
   //   headers: {'Authorization': 'Basic ' + Buffer.from(username + ":" + password).toString('base64')}
    })
      .then(async response => {
        return response.json();
      })
      .then(responseJson => {
        return responseJson
      });
    return ret
  }

  static async logout() {
    const url = BASE_URL + 'auth/logout';
  //  const username = 'assine';
  //  const password = 'nsc';
    await fetch(url, {
      method: 'post',
      credentials: 'include',
   //   headers: {'Authorization': 'Basic ' + Buffer.from(username + ":" + password).toString('base64')}
    });
  }

  static async getProduct(product) {
    const url = BASE_URL + 'product';
  //  const username = 'assine';
  //  const password = 'nsc';
    return await fetch(url, {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
   //     'Authorization': 'Basic ' + Buffer.from(username + ":" + password).toString('base64')
      },
      body: JSON.stringify(product),
    }).then(async response => {
      return response.json();
    }).then(responseJson => {
      return responseJson
    }).catch(err => {
      return {
        status: 400
      };
    });
  }

  static async getCities(stateCode) {
    const url = BASE_URL + `loja/getCities?state=${stateCode}`;
  //  const username = 'assine';
  //  const password = 'nsc';
    return await fetch(url, {
      method: 'get',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
 //       'Authorization': 'Basic ' + Buffer.from(username + ":" + password).toString('base64')
      },
    }).then(async response => {
      return response.json();
    }).then(responseJson => {
      return responseJson.cities
    }).catch(err => {
      return {
        status: 400
      };
    });
  }

  static async getNeighborhoods(stateCode, cityCode) {
    const url = BASE_URL + `loja/getNeighborhoods?state=${stateCode}&cityCode=${cityCode}`;
  //  const username = 'assine';
  //  const password = 'nsc';
    return await fetch(url, {
      method: 'get',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
  //      'Authorization': 'Basic ' + Buffer.from(username + ":" + password).toString('base64')
      },
    }).then(async response => {
      return response.json();
    }).then(responseJson => {
      return responseJson.neighborhoods
    }).catch(err => {
      return {
        status: 400
      };
    });
  }

  static async getStreets(stateCode, cityCode, name) {
    const url = BASE_URL + `loja/getStreets?state=${stateCode}&cityCode=${cityCode}&name=${name}`;
   // const username = 'assine';
   // const password = 'nsc';
    return await fetch(url, {
      method: 'get',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
    //    'Authorization': 'Basic ' + Buffer.from(username + ":" + password).toString('base64')
      },
    }).then(async response => {
      return response.json();
    }).then(responseJson => {
      return responseJson.streets
    }).catch(err => {
      return {
        status: 400
      };
    });
  }

  static async getComplements(stateCode, cityCode, name) {
    const url = BASE_URL + `loja/getComplements`;
   // const username = 'assine';
  //  const password = 'nsc';
    return await fetch(url, {
      method: 'get',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
    //    'Authorization': 'Basic ' + Buffer.from(username + ":" + password).toString('base64')
      },
    }).then(async response => {
      return response.json();
    }).then(responseJson => {
      return responseJson.complements
    }).catch(err => {
      return {
        status: 400
      };
    });
  }

  static async getBanks(newspaper) {
    const url = BASE_URL + `loja/getBanks?newspaper=${newspaper}`;
  //  const username = 'assine';
  //  const password = 'nsc';
    return await fetch(url, {
      method: 'get',
      credentials: 'include',
      headers: {
        'Content-type': 'application/json',
   //     'Authorization': 'Basic ' + Buffer.from(username + ":" + password).toString('base64')
      },
    }).then(async response => {
      return response.json();
    }).then(responseJson => {
      return responseJson.banks
    }).catch(err => {
      return {
        status: 400
      };
    });
  }

  static async getAccountVerify(newspaperCode, paymentTypeCode, bankCode, agencyCode, documentNum) {
    const url = BASE_URL + 'loja/account/verify?newspaperCode='+newspaperCode+
    '&paymentTypeCode='+paymentTypeCode+
    '&bankCode='+bankCode+
    '&agencyCode='+agencyCode+
    '&documentNum='+documentNum;
  //  const username = 'assine';
  //  const password = 'nsc';
    return await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
   //     'Authorization': 'Basic ' + Buffer.from(username + ":" + password).toString('base64')
      },
    }).then(async response => {
      return response.json();
    }).then(responseJson => {
      return responseJson.status
    }).catch(err => {
      return {
        status: 400
      };
    });
  }
}

export default ApiService;
