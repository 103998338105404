import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    wrapper: {
        display: 'flex',
        flexFlow: 'column',
        width: '124px',
    },
    name: {
        color: '#848585',
        fontWeight: 'bold',
        fontSize: '19px',
        textAlign: 'center',
        minHeight: '48px'
    },
    radio: {
        margin: '10px auto'
    }
});

class JornalAssinatura extends React.Component {

  constructor(props) {
    super(props);

    this.name = props.name;
    this.image = props.image;
    this.code = props.code;
    this.onChange = props.onChange;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        <label className={classes.name}>{this.props.name}</label>
        <img
            src={this.props.image}
            alt={this.props.name}
            height="152"
            width="124"/>
        <input
            onChange={this.props.onChange}
            className={classes.radio}
            type="radio"
            id={this.props.code}
            name="channel_code"
            value={this.props.code}
            />
      </div>
    );
  }
}

export default withStyles(styles)(JornalAssinatura);
