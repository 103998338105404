import React, { Component } from "react";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import TagManager from 'react-gtm-module';
import Header from './Components/Header';
import './App.css';


const themeNSC = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#29CC44',
    },
    secondary: {
      main: '#0078FF',
    },
  },
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#fff',
      }
    }
  }
});

const Layout = (WrappedComponent, step = false, renderOnlyThisStep = false) =>
  class Layout extends Component {
    render() {
      return (
        <div className="App">
          <Helmet>
            <title>Loja NSC</title>
            <TagManager />
          </Helmet>
          <MuiThemeProvider theme={themeNSC}>
            <Header step={step} renderOnlyThisStep={renderOnlyThisStep} />
            <WrappedComponent {...this.props} />

          </MuiThemeProvider>
        </div>
      );
    }
  };

export default Layout;

