import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import imagemNscColorido from '../img/NSC_colorido.png';
import imageNscWhite from '../img/NSC_white.png';
import buttonAppGoogleNsc from '../img/button_google_play.png';
import buttonAppStoreNsc from '../img/button_app_store.png';

import nscImg1 from '../img/nsc-total-app/nsc-img1.png';
import nscImg2 from '../img/nsc-total-app/nsc-img2.png';
import nscImg3 from '../img/nsc-total-app/nsc-img3.png';
import nscImg4 from '../img/nsc-total-app/nsc-img4.png';

import CourselNscTotal from './CourselApp';


const styles = theme => ({
  divAplicativoNoticia: {
    width: '100%',
    height: '7rem',
    marginBottom: '20px',
    background: 'linear-gradient(270deg, #FFFFFF 13.22%, rgba(255, 255, 255, 0) 70.63%), #FF6915',
    '& #mobile': {
      display: 'none'
    },
    '& img': {
      width: '6rem',
      float: 'right',
      margin: '25px'
    },
    '@media (max-width: 750px)': {
      height: '6rem',
      background: ' linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 52.23%), #FF6915',
      '& #desk': {
        display: 'none'
      },
      '& #mobile': {
        display: 'block',
        width: '10%',
        marginLeft: '0'
      }
    }
  },
  nscTotalInfo: {
    float: 'left',
    textAlign: 'left',
    color: '#FFFFFF',
    margin: '18px',
    '& p:nth-of-type(1)': {
      fontWeight: 'bold',
      fontSize: '1.5rem',
      margin: '0'
    },
    '& p:nth-of-type(2)': {
      fontSize: '1.2rem',
      margin: '0'
    },
    '@media (max-width: 750px)': {
      margin: '10px',
      '& p:nth-of-type(1)': {
        fontSize: '1.2rem'
      },
      '& p:nth-of-type(2)': {
        fontSize: '1rem'
      }
    }
  },
  divTextAplicativoNoticiaBaixo: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '30px',
    color: '#FFFFFF',
  },
  divAppNsc: {
    margin: 'auto auto 2%',
    display: 'inline-flex',
    '& img': {
      width: '100%'
    }
  },
  invertBackground: {
    background: 'linear-gradient(90deg, #FFFFFF 13.22%, rgba(255, 255, 255, 0) 70.63%), #FF6915',
    '& img': {
      float: 'left'
    },
    '@media (max-width: 750px)': {
      background: ' linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 52.23%), #FF6915',
      '& img': {
        float: 'right'
      }
    }
  },
  invertText: {
    float: 'right',
    textAlign: 'right',
    '@media (max-width: 750px)': {
      float: 'left',
      textAlign: 'left',
    }
  },
  buttonApp: {
    background: 'none',
    border: 'none',
    cursor: 'pointer'
  }
});

class AppNscDigital extends Component {
  constructor(props) {
    super(props);

    this.handleGoToStore = this.handleGoToStore.bind(this);

    this.state = {
      store: {
        android: 'https://play.google.com/store/apps/details?id=com.nsctotalapp',
        ios: 'https://apps.apple.com/br/app/nsc-total/id1489425577'
      }
    };
  }

  handleGoToStore(event) {
    const store = event.currentTarget.getAttribute('id');
    window.location.href = store.indexOf('ios') !== -1 ? this.state.store.ios : this.state.store.android;
  }

  render() {

    const { classes, invertBackground } = this.props;

    const img = [
      { id: 1, img: nscImg1 },
      { id: 2, img: nscImg2 },
      { id: 3, img: nscImg3 },
      { id: 4, img: nscImg4 }
    ];

    return (
      <div>
        <div className={`${classes.divAplicativoNoticia} ${invertBackground && classes.invertBackground}`}>
          <div className={`${classes.nscTotalInfo} ${invertBackground && classes.invertText}`}>
            <p>Baixe agora o app NSC Total</p>
            <p>O seu aplicativo de notícias</p>
          </div>
          <img src={imagemNscColorido} id="desk" alt="" />
          <img src={imageNscWhite} id="mobile" alt="" />
        </div>

        <CourselNscTotal images={img} />

        <div className={classes.divAppNsc}>
          <button onClick={this.handleGoToStore} className={classes.buttonApp} id="app-total-and">
            <img src={buttonAppGoogleNsc} alt="" />
          </button>
          <button onClick={this.handleGoToStore} className={classes.buttonApp} id="app-total-ios">
            <img src={buttonAppStoreNsc} alt="" />
          </button>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(AppNscDigital);
