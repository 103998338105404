import { getEnv } from "./Utils";
import UserSession from './../Services/UserSession';


const config = {
  autorizeUrl: getEnv('REACT_APP_LOGIN_URL'),
  logoutUrl: getEnv('REACT_APP_LOGOUT_URL'),
  profileUrl: getEnv('REACT_APP_PROFILE_URL'),
  assineNsc: {
    clientId: getEnv('REACT_APP_ASSINE_NSC_CLIENT_ID'),
    redirectUri: getEnv('REACT_APP_ASSINE_NSC_REDIRECT_URI'),
    logoutUri: getEnv('REACT_APP_ASSINE_NSC_LOGOUT_URI'),
  },
  nscTotal: {
    clientId: getEnv('REACT_APP_NSC_TOTAL_CLIENT_ID'),
    redirectUri: getEnv('REACT_APP_NSC_TOTAL_REDIRECT_URI'),
    homeUri: getEnv('REACT_APP_NSC_TOTAL_REDIRECT_HOME_URI'),
    loginUri: getEnv('REACT_APP_LOGIN_REDIRECT_URL'),
  }
}

export default class UrlHelper {

  static getQueryString(name) {
    const params = new URLSearchParams(window.location.search);
    const value = params.get(name);
    return value || null;
  }

  static buildQuery(params) {
    const query = Object.keys(params || {})
      .filter(k => params[k] !== undefined && params[k] !== null)
      .map(k => `${k}=${encodeURIComponent(params[k])}`)
      .join('&');

    return query ? `?${query}` : '';
  }

  static buildUrl(base, params) {
    const query = this.buildQuery(params);
    return `${base}${query}`;
  }

  static buildRedirectUrl(state = '') {
    if(state?.startsWith('http://') || state?.startsWith('https://')) {
      return state;
    }
    return this.buidNscTotalUrl(state);
  }

  static buidNscTotalUrl(state = '') {
    return `${config.autorizeUrl}?client_id=${config.nscTotal.clientId}&redirect_uri=${config.nscTotal.redirectUri}&state=${state || ''}`;
  }

  static buildLoginUrl(assineNscState, nscTotalState = '') {
    const state = this.encodeStates([assineNscState, nscTotalState]);
    const userData = UserSession.getUserData();
    if(userData.isLoggedIn) {
      return assineNscState;
    }
    return `${config.autorizeUrl}?client_id=${config.assineNsc.clientId}&redirect_uri=${config.assineNsc.redirectUri}&state=${state || ''}`;
  }
  static buidNscTotalLoginRedirectUrl() {
    return `${config.autorizeUrl}?client_id=${config.nscTotal.clientId}&redirect_uri=${config.nscTotal.redirectUri}&state=home`;
  }

  static buildLogoutUrl() {
    return `${config.logoutUrl}?client_id=${config.assineNsc.clientId}&logout_uri=${config.assineNsc.logoutUri}`;
  }

  static buildProfileUrl(assineNscState) {
    const state = this.encodeStates([assineNscState, '']);
    return `${config.profileUrl}?client_id=${config.assineNsc.clientId}&redirect_uri=${config.assineNsc.redirectUri}&state=${state || ''}`;
  }

  static buidLoginCallbackPath(code, assineNscState) {
    const state = this.encodeStates([assineNscState, '']);
    return `login-callback?code=${code}&state=${state || ''}`;
  }

  static encodeStates(values) {
    return (values || [])
      .map(v => ((v === undefined || v === null) ? '' : (v + '')).replace(';', ';;'))
      .join(';');
  }

  static decodeStates(value) {
    return (value || '')
      .replace(/;;/g, '\0')
      .split(';')
      .map(v => v.replace(/\0/g, ';'));
  }
}
