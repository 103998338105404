import TagManager from 'react-gtm-module';
// import UserSession from './UserSession';

class TagManagerService {

  static isEmployee(userData) {
    if (!userData || userData.email === undefined) return null;
    return userData.email.indexOf("somosnsc.com.br") !== -1;
  }

  static getUserType(userData) {
    if (!userData) return "NOT_LOGGED_IN";

    const userProducts = userData.user.products || "";
    return userProducts === "" ? "LOGGED_IN" : "SUBSCRIBER";
  }

  static async initialize() {

    //const userData = await UserSession.getUserData();
    const userData = { user: {} };

    const tagManagerArgs = {
      gtmId: 'GTM-NBXJGMV',
      dataLayer: {
        user: {
          type: this.getUserType(userData),
          id: userData.user.username,
          employee: this.isEmployee(userData.user),
        }
      }
    }
    TagManager.initialize(tagManagerArgs);
  }
}

export default TagManagerService;
